import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { FaBars, FaUser } from 'react-icons/fa';
import { FaFacebookF, FaTwitter, FaInstagram,FaTimes } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../Assets/logo.jpg';

const ReservationCrud = () => {
  const [reservations, setReservations] = useState([]);

  const [isOpen, setIsOpen] = useState(false); // State to manage the navbar for mobile view
const [isOpenUserMenu, setIsOpenUserMenu] = useState(false); // State for user menu
  const token = localStorage.getItem('Token');
  const navigate = useNavigate();

 

const fetchReservations = useCallback(async () => {
  try {
    const res = await axios.get('https://ituzebe.onrender.com/api/reservations', {
      headers: { Authorization: `Bearer ${token}` },
    });
    setReservations(res.data);
  } catch (err) {
    console.error(err);
  }
}, [token]);

useEffect(() => {
  fetchReservations();
}, [fetchReservations]);

  const handleLogout = () => {
    localStorage.removeItem('Token');
    navigate('/signin');
  };


  const handleNavbarToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="min-h-screen bg-stone-900 text-white flex flex-col font-Arial">
       {/* Header section */}
      <header className="sticky top-0 z-20 shadow-md flex justify-between items-center p-4 bg-gray-800">
        <div className="flex items-center space-x-4">
          <img src={logo} alt="Ituze Garden Palace Logo" className="h-20 w-20 rounded-full" />
          <h1 className="text-2xl font-bold">Ituze Garden Palace</h1>
        </div>
        <div className="flex items-center space-x-4">
          <button onClick={handleNavbarToggle} className="text-3xl md:hidden">
            {isOpen ? <FaTimes /> : <FaBars />}
          </button>
          <nav className={`flex-col md:flex-row ${isOpen ? 'flex' : 'hidden'} md:flex`}>
            <ul className="block md:flex md:space-x-4 md:items-center md:text-lg md:font-semibold md:space-x-8 md:ml-auto">
              <li className="hover:underline"><Link to="/">Home</Link></li>
              <li className="hover:underline"><Link to="/gallery">Gallery</Link></li>
              <li className="hover:underline"><Link to="/reservation">Reservation</Link></li>
              <li className="hover:underline"><Link to="/menu">Menu</Link></li>
               <li className="hover:underline"><Link to="/contact">Contacts</Link></li>
            </ul>
          </nav>
          <div className="relative">
            <FaUser className="text-3xl cursor-pointer" onClick={() => setIsOpenUserMenu(!isOpenUserMenu)} />
            {isOpenUserMenu && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 text-black">
                <Link to="/profile" className="block px-4 py-2 hover:bg-gray-200">Profile</Link>
                <button onClick={handleLogout} className="block px-4 py-2 hover:bg-gray-200">Logout</button>
              </div>
            )}
          </div>
        </div>
      </header>

      {/* Main Content */}
      <section className="py-16 bg-orange-100 text-center">
       <h1 className="text-2xl font-bold mb-5 text-gray-600">Available Reservations</h1>
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-4">
          {reservations.map((reservation) => (
            <div key={reservation._id} className="bg-gray-800 rounded-lg shadow-lg p-6 text-left">
              <h3 className="text-xl font-bold mb-2 text-orange-400">{reservation.name}</h3>
              <p className="mb-2 text-gray-300">{reservation.description}</p>
              <p className="text-lg font-semibold text-white">RF {reservation.price}</p>
              <button
               
                className="mt-4 bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded transition"
              >
                Confirm with Deposit
              </button>
            </div>
          ))}
        </div>
      </section>

      {/* Footer */}
       <footer className="bg-gray-900 text-white py-10 text-center">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
            <div className="text-sm">
              <p>Kigali - Rwanda<br />Kimuhurura, KG 623 St</p>
            </div>
            <div>
              <h2 className="text-2xl font-semibold mb-2">Ituze Garden Palace</h2>
              <ul className="flex space-x-6">
                <li><a href="https://www.facebook.com/" aria-label="Facebook" className="hover:text-orange-400"><FaFacebookF /></a></li>
                <li><a href="https://twitter.com/" aria-label="Twitter" className="hover:text-orange-400"><FaTwitter /></a></li>
                <li><a href="https://www.instagram.com/" aria-label="Instagram" className="hover:text-orange-400"><FaInstagram /></a></li>
              </ul>
            </div>
            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3987.3700937917183!2d30.189746909868628!3d-2.007285197966163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19db5754ee2e87f5%3A0x8d6336f2eaad9694!2sItuze%20garden%20palace!5e0!3m2!1sen!2srw!4v1719416061512!5m2!1sen!2srw"
                width="300"
                height="200"
                style={{ border: '0' }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="rounded-lg shadow-lg"
                 title="Ituze Garden Palace Location"
              ></iframe>
            </div>
          </div>
          <div className="mt-6 text-sm">
            <p>FAQ | Terms & Conditions | Privacy Policy | Shipping Policy | Refund Policy | Cookie Policy</p>
          </div>
          <div className="mt-4 text-xs">
            <p>&copy;2024 Ituze Garden Palace. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ReservationCrud;
