import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';

const MenuCrud = () => {
  const [menus, setMenus] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    price: '',
    type: 'Foods', // Default to 'Foods' for new menu items
  });
  const [updatingId, setUpdatingId] = useState(null);
  const [activeTab, setActiveTab] = useState('Foods'); // Track the active tab
  const token = localStorage.getItem('Token');

 const fetchMenus = useCallback(async () => {
    try {
      const res = await axios.get('https://ituzebe.onrender.com/api/menus', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setMenus(res.data);
    } catch (err) {
      console.error(err);
    }
  }, [token]);

  useEffect(() => {
    fetchMenus();
  }, [fetchMenus]);

  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://ituzebe.onrender.com/api/menus', formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setFormData({
        name: '',
        price: '',
        type: 'Foods',
      });
      fetchMenus();
    } catch (err) {
      console.error(err);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://ituzebe.onrender.com/api/menus/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchMenus();
    } catch (err) {
      console.error(err);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://ituzebe.onrender.com/api/menus/${updatingId}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setFormData({
        name: '',
        price: '',
        type: 'Foods',
      });
      setUpdatingId(null);
      fetchMenus();
    } catch (err) {
      console.error(err);
    }
  };

  const handleEdit = (menu) => {
    setFormData({
      name: menu.name,
      price: menu.price,
      type: menu.type,
    });
    setUpdatingId(menu._id);
  };

 
  const renderMenuItems = (type) => {
    return menus.filter(menu => menu.type === type).map(menu => (
      <div key={menu._id} className="flex justify-between mb-2 items-center">
        <div>
          <span>{menu.name}</span> - <span>{menu.price}</span>
        </div>
        <div className="flex space-x-2">
          <button
            onClick={() => handleEdit(menu)}
            className="text-blue-500 hover:text-blue-700"
          >
            <FaEdit />
          </button>
          <button
            onClick={() => handleDelete(menu._id)}
            className="text-red-500 hover:text-red-700"
          >
            <FaTrashAlt />
          </button>
        </div>
      </div>
    ));
  };

  return (
    <div className="min-h-screen text-white font-Arial">
      {/* Main Content */}
      <div className="mx-auto px-4 py-8 bg-white text-black">
        <h1 className="text-4xl font-semibold mb-8">Menu CRUD</h1>

        {/* Add/Edit Menu Form */}
        <form onSubmit={updatingId ? handleUpdate : handleCreate} className="mb-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500"
              required
            />
            <input
              type="number"
              name="price"
              placeholder="Price"
              value={formData.price}
              onChange={(e) => setFormData({ ...formData, price: e.target.value })}
              className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500"
              required
            />
            <div>
              <label htmlFor="type" className="block">Type:</label>
              <select
                id="type"
                name="type"
                value={formData.type}
                onChange={(e) => setFormData({ ...formData, type: e.target.value })}
                className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500"
                required
              >
                <option value="Foods">Food</option>
                <option value="Drinks">Drinks</option>
              </select>
            </div>
          </div>
          <div className="mt-4">
            <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
              {updatingId ? 'Update Menu' : 'Add Menu'}
            </button>
            {updatingId && (
              <button
                type="button"
                onClick={() => {
                  setFormData({ name: '', price: '', type: 'Foods' });
                  setUpdatingId(null);
                }}
                className="ml-2 bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
              >
                Cancel
              </button>
            )}
          </div>
        </form>

        {/* Tabs for switching between Food and Drinks */}
        <div className="mb-8">
          <div className="flex justify-center space-x-4 mb-4">
            <button
              className={`py-2 px-4 ${activeTab === 'Foods' ? 'border-b-2 border-red-600' : ''}`}
              onClick={() => setActiveTab('Foods')}
            >
              Food Menu
            </button>
            <button
              className={`py-2 px-4 ${activeTab === 'Drinks' ? 'border-b-2 border-red-600' : ''}`}
              onClick={() => setActiveTab('Drinks')}
            >
              Drinks Menu
            </button>
          </div>

          <h2 className="text-2xl font-semibold mb-4">{activeTab} Menu</h2>
          {renderMenuItems(activeTab)}
        </div>
      </div>
    </div>
  );
};

export default MenuCrud;
