import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { FaBars, FaUser } from 'react-icons/fa';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import { FaTimes } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../Assets/logo.jpg';

const Menu = () => {
  const [menus, setMenus] = useState([]);
  const [isOpen, setIsOpen] = useState(false); // For mobile menu toggle
  const [isOpenUserMenu, setIsOpenUserMenu] = useState(false); // For user menu toggle
  const [activeTab, setActiveTab] = useState('Foods'); // Track the active tab
  const token = localStorage.getItem('Token');
  const navigate = useNavigate();

  const fetchMenus = useCallback(async () => {
    try {
      const res = await axios.get('https://ituzebe.onrender.com/api/menus', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setMenus(res.data);
    } catch (err) {
      console.error(err);
    }
  }, [token]);

  useEffect(() => {
    fetchMenus();
  }, [fetchMenus]);

  const handleNavbarToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('Token');
    navigate('/signin');
  };

  const renderMenuItems = (type) => {
    return menus.filter(menu => menu.type === type).map(menu => (
      <div key={menu._id} className="flex justify-between mb-2 font-mono">
        <span>{menu.name}</span>
        <span>{menu.price}</span>
      </div>
    ));
  };

  return (
    <div className="min-h-screen bg-stone-900 text-white font-Arial">
      {/* Header section */}
      <header className="sticky top-0 z-20 shadow-md flex justify-between items-center p-4 bg-gray-800">
        <div className="flex items-center space-x-4">
          <img src={logo} alt="Ituze Garden Palace Logo" className="h-20 w-20 rounded-full" />
          <h1 className="text-2xl font-bold">Ituze Garden Palace</h1>
        </div>
        <div className="flex items-center space-x-4">
          <button onClick={handleNavbarToggle} className="text-3xl md:hidden">
            {isOpen ? <FaTimes /> : <FaBars />}
          </button>
          <nav className={`flex-col md:flex-row ${isOpen ? 'flex' : 'hidden'} md:flex`}>
            <ul className="block md:flex md:space-x-4 md:items-center md:text-lg md:font-semibold md:space-x-8 md:ml-auto">
              <li className="hover:underline"><Link to="/">Home</Link></li>
              <li className="hover:underline"><Link to="/gallery">Gallery</Link></li>
              <li className="hover:underline"><Link to="/reservation">Reservation</Link></li>
              <li className="hover:underline"><Link to="/menu">Menu</Link></li>
              <li className="hover:underline"><Link to="/contact">Contacts</Link></li>
            </ul>
          </nav>
          <div className="relative">
            <FaUser className="text-3xl cursor-pointer" onClick={() => setIsOpenUserMenu(!isOpenUserMenu)} />
            {isOpenUserMenu && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1">
                <Link to="/profile" className="block px-4 py-2 text-gray-800 hover:bg-gray-200">Profile</Link>
                <button onClick={handleLogout} className="block px-4 py-2 text-gray-800 hover:bg-gray-200">Logout</button>
              </div>
            )}
          </div>
        </div>
      </header>

      {/* Main Content */}
      <div className="min-h-screen mx-auto px-4 py-8 bg-white text-black">
        {/* Tabs for switching between Food and Drinks */}
        <div className="mb-8">
          <div className="flex justify-center space-x-4 mb-4">
            <button
              className={`py-2 px-4 font-bold ${activeTab === 'Foods' ? 'border-b-2 border-red-600' : ''}`}
              onClick={() => setActiveTab('Foods')}
            >
              Food Menu
            </button>
            <button
              className={`py-2 px-4 font-bold ${activeTab === 'Drinks' ? 'border-b-2 border-red-600' : ''}`}
              onClick={() => setActiveTab('Drinks')}
            >
              Drinks Menu
            </button>
          </div>

          <h2 className="text-2xl font-semibold mb-4">{activeTab} Menu</h2>
          {renderMenuItems(activeTab)}
        </div>
      </div>

      <footer className="bg-gray-900 text-white py-10 text-center">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
            <div className="text-sm">
              <p>Kigali - Rwanda<br />Kimuhurura, KG 623 St</p>
            </div>
            <div>
              <h2 className="text-2xl font-semibold mb-2">Ituze Garden Palace</h2>
              <ul className="flex space-x-6">
                <li><a href="https://www.facebook.com/" aria-label="Facebook" className="hover:text-orange-400"><FaFacebookF /></a></li>
                <li><a href="https://twitter.com/" aria-label="Twitter" className="hover:text-orange-400"><FaTwitter /></a></li>
                <li><a href="https://www.instagram.com/" aria-label="Instagram" className="hover:text-orange-400"><FaInstagram /></a></li>
              </ul>
            </div>
            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3987.3700937917183!2d30.189746909868628!3d-2.007285197966163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19db5754ee2e87f5%3A0x8d6336f2eaad9694!2sItuze%20garden%20palace!5e0!3m2!1sen!2srw!4v1719416061512!5m2!1sen!2srw"
                width="300"
                height="200"
                style={{ border: '0' }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="rounded-lg shadow-lg"
                title="Ituze Garden Palace Location"
              ></iframe>
            </div>
          </div>
          <div className="mt-6 text-sm">
            <p>FAQ | Terms & Conditions | Privacy Policy | Shipping Policy | Refund Policy | Cookie Policy</p>
          </div>
          <div className="mt-4 text-xs">
            <p>&copy;2024 Ituze Garden Palace. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Menu;
