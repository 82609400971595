import React, { useState, useEffect } from 'react';
import { FaMapMarkerAlt, FaTimes, FaPhoneAlt, FaEnvelope, FaFacebookF, FaTwitter, FaInstagram, FaUser, FaBars } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../Assets/logo.jpg';

const Contact = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isOpenUserMenu, setIsOpenUserMenu] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: ''
  });

  const fetchMessages = async () => {
    try {
      const token = localStorage.getItem('Token');
      const response = await axios.get('https://ituzebe.onrender.com/api/messages/client', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setMessages(response.data.data);
    } catch (error) {
      console.error('Error fetching messages:', error);
      // Handle error fetching messages
      toast.error('Failed to fetch messages. Please try again later.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Fetch messages by client ID on component mount
  useEffect(() => {
    fetchMessages();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem('Token');
      await axios.post('https://ituzebe.onrender.com/api/messages', formData, {
        headers: { Authorization: `Bearer ${token}` }
      });

      // Clear form after successful submission
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        message: ''
      });

      // Fetch updated messages after submission
      fetchMessages();

      // Show success toast
      toast.success('Message sent successfully!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      // Optionally, redirect or show success message
      navigate('/');
    } catch (error) {
      console.error('Error submitting form:', error);
      // Show error toast
      toast.error('Failed to send message. Please try again later.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem('Token');
      await axios.delete(`https://ituzebe.onrender.com/api/messages/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      // Filter out deleted message from state
      setMessages(messages.filter(message => message._id !== id));

      // Show success toast
      toast.success('Message deleted successfully!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error('Error deleting message:', error);
      // Show error toast
      toast.error('Failed to delete message. Please try again later.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleNavbarToggle = () => {
    setIsOpen(!isOpen);
  };

 
 
const handleLogout = () => {
    localStorage.removeItem('Token');
    navigate('/signin');
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white flex flex-col font-Arial">
      <ToastContainer /> {/* ToastContainer component to render toast notifications */}
      {/* Header section */}
      <header className="sticky top-0 z-20 shadow-md flex justify-between items-center p-4 bg-gray-800">
        <div className="flex items-center space-x-4">
          <img src={logo} alt="Ituze Garden Palace Logo" className="h-20 w-20 rounded-full" />
          <h1 className="text-2xl font-bold">Ituze Garden Palace</h1>
        </div>
        <div className="flex items-center space-x-4">
          <button onClick={handleNavbarToggle} className="text-3xl md:hidden">
            {isOpen ? <FaTimes /> : <FaBars />}
          </button>
          <nav className={`flex-col md:flex-row ${isOpen ? 'flex' : 'hidden'} md:flex`}>
            <ul className="block md:flex md:space-x-4 md:items-center md:text-lg md:font-semibold md:space-x-8 md:ml-auto">
              <li className="hover:underline"><Link to="/">Home</Link></li>
              <li className="hover:underline"><Link to="/gallery">Gallery</Link></li>
              <li className="hover:underline"><Link to="/reservation">Reservation</Link></li>
              <li className="hover:underline"><Link to="/menu">Menu</Link></li>
               <li className="hover:underline"><Link to="/contact">Contacts</Link></li>
            </ul>
          </nav>
          <div className="relative">
            <FaUser className="text-3xl cursor-pointer" onClick={() => setIsOpenUserMenu(!isOpenUserMenu)} />
            {isOpenUserMenu && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 text-black">
                <Link to="/profile" className="block px-4 py-2 hover:bg-gray-200">Profile</Link>
                <button onClick={handleLogout} className="block px-4 py-2 hover:bg-gray-200">Logout</button>
              </div>
            )}
          </div>
        </div>
      </header>

      <div className="bg-gray-800 text-white flex justify-center items-center">
        <div className="container mx-auto p-4">
          <h1 className="text-4xl font-semibold mb-8 text-center">Contact</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Contact Information */}
            <div className="flex flex-col space-y-4">
              <div className="flex items-center text-gray-300">
                <FaMapMarkerAlt className="text-2xl mr-4" />
                <div>
                  <p>KN 14 AVE, KG 623 St, Kigali - Rwanda</p>
                </div>
              </div>
              <div className="flex items-center text-gray-300">
                <FaPhoneAlt className="text-2xl mr-4" />
                <div>
                  <p>+250 784 503 706 </p>
                </div>
              </div>
              <div className="flex items-center text-gray-300">
                <FaEnvelope className="text-2xl mr-4" />
                <div>
                  <p>ituzegardenpalace@gmail.com</p>
                </div>
              </div>
              <div className="flex space-x-4 mt-4">
                <a href="https://www.facebook.com/" aria-label="Facebook" className="text-gray-300 hover:text-blue-500">
                  <FaFacebookF className="text-2xl cursor-pointer" />
                </a>
                <a href="https://twitter.com/" aria-label="Twitter" className="text-gray-300 hover:text-blue-500">
                  <FaTwitter className="text-2xl cursor-pointer" />
                </a>
                <a href="https://www.instagram.com/" aria-label="Instagram" className="text-gray-300 hover:text-blue-500">
                  <FaInstagram className="text-2xl cursor-pointer" />
                </a>
              </div>
            </div>
            {/* Contact Form */}
            <form onSubmit={handleSubmit} className="flex flex-col space-y-4 bg-gray-700 p-6 text-white rounded-lg shadow-md">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="border border-gray-600 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500"
                  required
                />
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="border border-gray-600 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500"
                  required
                />
              </div>
              <input
                type="email"
                name="email"
                placeholder="Email *"
                value={formData.email}
                onChange={handleChange}
                className="border border-gray-600 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500 text-black"
                required
              />
              <textarea
                name="message"
                placeholder="Message"
                value={formData.message}
                onChange={handleChange}
                className="border border-gray-600 rounded-md px-3 py-2 h-32 focus:outline-none focus:border-blue-500 text-black"
              />
              <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500">
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
      <section className="bg-gray-800 text-white py-8">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-semibold mb-4">Messages</h2>
          {messages.length === 0 ? (
            <p className="text-gray-400">No messages to display.</p>
          ) : (
            <ul className="space-y-4">
              {messages.map(message => (
                <li key={message._id} className="bg-gray-700 p-4 rounded-lg shadow-md">
                  <div className="flex justify-between">
                    <div>
                      <h3 className="text-lg font-semibold">{message.firstName} {message.lastName}</h3>
                      <p className="text-gray-400">{message.email}</p>
                      <p className="mt-2">{message.message}</p>
                      {message.adminReply && (
                        <div className="mt-2 border-t border-gray-600 pt-2">
                          <p className="text-gray-500">Admin Reply:</p>
                          <p>{message.adminReply}</p>
                        </div>
                      )}
                    </div>
                    <div className="flex items-center space-x-4">
                      <button
                        className="text-red-500 hover:text-red-700"
                        onClick={() => handleDelete(message._id)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </section>
      <footer className="bg-gray-900 text-white py-10 text-center">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
            <div className="text-sm">
              <p>Kigali - Rwanda<br />Kimuhurura, KG 623 St</p>
            </div>
            <div>
              <h2 className="text-2xl font-semibold mb-2">Ituze Garden Palace</h2>
              <ul className="flex space-x-6">
                <li><a href="https://www.facebook.com/" aria-label="Facebook" className="hover:text-blue-500"><FaFacebookF /></a></li>
                <li><a href="https://twitter.com/" aria-label="Twitter" className="hover:text-blue-500"><FaTwitter /></a></li>
                <li><a href="https://www.instagram.com/" aria-label="Instagram" className="hover:text-blue-500"><FaInstagram /></a></li>
              </ul>
            </div>
            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3987.3700937917183!2d30.189746909868628!3d-2.007285197966163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19db5754ee2e87f5%3A0x8d6336f2eaad9694!2sItuze%20garden%20palace!5e0!3m2!1sen!2srw!4v1719416061512!5m2!1sen!2srw"
                width="300"
                height="200"
                style={{ border: '0' }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="rounded-lg shadow-lg"
title="Ituze Garden Palace Location"
              ></iframe>
            </div>
          </div>
          <div className="mt-6 text-sm">
            <p>FAQ | Terms & Conditions | Privacy Policy | Shipping Policy | Refund Policy | Cookie Policy</p>
          </div>
          <div className="mt-4 text-xs">
            <p>&copy;2024 Ituze Garden Palace. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Contact;
