import { useEffect, useState } from 'react';



const HeaderComponent = ({ page, logout }) => {
    
    const [email, setEmail] = useState('')
    const [role, setRole] = useState("")
  
    
    useEffect(() => {
        
        const userData = JSON.parse(localStorage.getItem("loggedInUser"));
        setEmail(userData.email)
        setRole(userData.role)
    }, [])

    return (
        <div className="bg-white rounded-lg shadow-md overflow-hidden" style={{ fontFamily: 'inter' }}>
            <div className="px-4 py-3">
                <div className="flex flex-row">
                    <div className='mt-2 mr-3'>
                    <i className="fa-solid fa-user" style={{fontSize:"1.5em"}}></i>
                    </div>
                    <div>
                        <p className="text-gray-500 text-sm">{email}</p>
                        <p className="text-gray-500 text-sm"><small>{role}</small></p>
                    </div>
                </div>
            </div>
            <hr />
            
            <div className="px-4 py-3 cursor-pointer hover:bg-gray-100" onClick={() => logout()}>
                <div className='flex flex-row items-center'>
                    <i className="fa-solid fa-right-from-bracket mr-2"></i>
                    <p className='text-sm text-gray-500'>Logout</p>
                </div>
            </div>
            {/* Modal */}
          
        </div>
    );
};

export default HeaderComponent;