import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify'; // Import toast components
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for react-toastify
import { FaReply } from 'react-icons/fa';


const AdminMessages = () => {
  const [messages, setMessages] = useState([]);
  const [replyMessage, setReplyMessage] = useState('');
  
  useEffect(() => {
    fetchMessages();
  }, []);

  const fetchMessages = async () => {
    try {
      const token = localStorage.getItem('Token');
      const response = await axios.get('https://ituzebe.onrender.com/api/messages', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setMessages(response.data.data);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const handleReply = async (id) => {
    try {
      const token = localStorage.getItem('Token');
      await axios.put(`https://ituzebe.onrender.com/api/messages/${id}/reply`, { reply: replyMessage }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      toast.success('Reply sent successfully!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // Clear reply message after sending
      setReplyMessage('');
      // Refresh messages after reply
      fetchMessages();
    } catch (error) {
      console.error('Error replying to message:', error);
      toast.error('Failed to send reply. Please try again later.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

 
  return (
    <div className="min-h-screen bg-yellow-200 text-white flex flex-col">
      
      <ToastContainer /> {/* ToastContainer component to render toast notifications */}
      <div className="container mx-auto p-4">
        <h1 className="text-4xl font-semibold mb-8 text-center">Admin Messages</h1>
        <div className="grid gap-4">
          {messages.map(message => (
            <div key={message._id} className="bg-white p-4 rounded-lg shadow-md">
              <p className="text-lg font-semibold">{message.firstName} {message.lastName}</p>
              <p className="text-blue-600">{message.email}</p>
              <p className="mt-2 text-gray-600">{message.message}</p>
              {message.adminReply && (
                <div className="mt-2 bg-gray-600 p-2 rounded-lg">
                  <p className="font-semibold">Admin Reply:</p>
                  <p>{message.adminReply}</p>
                </div>
              )}
              {!message.adminReply && (
                <div className="mt-2 text-gray-700">
                  <textarea
                    placeholder="Write your reply..."
                    value={replyMessage}
                    onChange={(e) => setReplyMessage(e.target.value)}
                    className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500"
                    rows="3"
                  />
                  <button
                    onClick={() => handleReply(message._id)}
                    className="bg-gray-500 text-white px-4 py-2 rounded-md mt-2 hover:bg-gray-600"
                  >
                    <FaReply className="inline-block mr-2" /> Reply
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
     
    </div>
  );
};

export default AdminMessages;
