// client/src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import Signin from './components/SignIn';
import Signup from './components/SignUp';
import Gallery from './components/Gallery';
import ReservationCrud from './components/ReservationCrud';
import MenuCrud from './components/MenuCrud';
import Contact from './components/Contact';
import AdminMessages from './components/AdminMessages';
import Sidebar from './components/Sidebar';
import DashboardLayout from './components/DashboardLayout';
import Reservation from './components/Reservation';
import GalleryCrud from './components/GalleryCrud';
import Menu from './components/Menu';

function App() {
  return (
    <Router>
       <Routes>
       <Route  path="/" element={<LandingPage/>} ></Route>
        <Route  path="/landingpage" element={<LandingPage/>} ></Route>
        <Route  path="/signin" element={<Signin/>} ></Route>
        <Route  path="/signup" element={<Signup/>} ></Route>
        <Route  path="/gallery" element={<GalleryCrud/>} ></Route>
        <Route  path="/reservation" element={<ReservationCrud/>} ></Route>
        <Route  path="/menu" element={<Menu/>} ></Route>
         <Route  path="/contact" element={<Contact/>} ></Route>
         <Route  path="/message" element={<AdminMessages/>} ></Route>
         <Route  path="/sidebar" element={<Sidebar/>} ></Route>
       
       
        <Route  path="/dashboard" element={<DashboardLayout/>} >
           <Route path="reserve" element={<Reservation />} />
           <Route  path="gallerys" element={<Gallery/>} />
            <Route  path="menus" element={<MenuCrud/>} />
           <Route  path="sidebar" element={<Sidebar/>} />
            <Route  path="message" element={<AdminMessages/>} />
            <Route  path="message" element={<AdminMessages/>} />
       </Route>
        </Routes>
    </Router>
  );
}

export default App;
