import React, { useEffect, useState } from 'react';
import { FaHome, FaReceipt, FaCogs,} from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../Assets/logo.jpg';

const Sidebar = ({ onClose }) => {
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState(null);
  const [activeItem, setActiveItem] = useState("/dashboard/dashboardd");


  useEffect(() => {
    // Fetch user role from localStorage
    const fetchUserRole = () => {
      const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));
      if (loggedInUser) {
        setUserRole(loggedInUser.role);
      }
    };

    fetchUserRole();
  }, []);

  const handleItemClick = (path) => {
    setActiveItem(path);
    navigate(path);
    onClose();
  };

  return (
    <div className="fixed top-0 bottom-0 left-0 w-56 shadow-lg font-Arial">
       <div className="flex justify-center space-x-4">
          <img src={logo} alt="Ituze Garden Palace Logo" className=" h-20 w-20 rounded-full" />
          
        </div>
<h1 className="text-xl text-center font-bold">Ituze Garden Palace</h1>
      <nav className="mt-10">
        {userRole === 'administrator' && (
          <>
            <Link
              to="/dashboard/dashboardd"
              onClick={() => handleItemClick('/dashboard/dashboardd')}
              className={`flex items-center p-2 mt-5 ${
                activeItem === '/dashboard/dashboardd' ? 'bg-gray-200' : ''
              }`}
              style={{
                paddingRight: activeItem === '/dashboard/dashboardd' ? '85px' : '',
                borderLeft: activeItem === '/dashboard/dashboardd' ? '4px solid #8155ff' : '',
              }}
            >
              <FaHome className="mr-3" /> Dashboard
            </Link>
            <Link
              to="/dashboard/gallerys"
              onClick={() => handleItemClick('/dashboard/gallerys')}
              className={`flex items-center p-2 mt-5 ${
                activeItem === '/dashboard/gallerys' ? 'bg-gray-200' : ''
              }`}
              style={{
                paddingRight: activeItem === '/dashboard/gallerys' ? '85px' : '',
                borderLeft: activeItem === '/dashboard/gallerys' ? '4px solid #8155ff' : '',
              }}
            >
              <FaReceipt className="mr-3" /> Manage Gallery
            </Link>
            <Link
              to="/dashboard/reserve"
              onClick={() => handleItemClick('/dashboard/reserve')}
              className={`flex items-center p-2 mt-5 ${
                activeItem === '/dashboard/reserve' ? 'bg-gray-200' : ''
              }`}
              style={{
                paddingRight: activeItem === '/dashboard/reserve' ? '85px' : '',
                borderLeft: activeItem === '/dashboard/reserve' ? '4px solid #8155ff' : '',
              }}
            >
              <FaCogs className="mr-3" /> Manage Reservation
            </Link>
            <Link
              to="/dashboard/menus"
              onClick={() => handleItemClick('/dashboard/menus')}
              className={`flex items-center p-2 mt-5 ${
                activeItem === '/dashboard/transactions' ? 'bg-gray-200' : ''
              }`}
              style={{
                paddingRight: activeItem === '/dashboard/menus' ? '85px' : '',
                borderLeft: activeItem === '/dashboard/menus' ? '4px solid #8155ff' : '',
              }}
            >
              <FaReceipt className="mr-3" /> Manage Menu
            </Link>
 <Link
              to="/dashboard/message"
              onClick={() => handleItemClick('/dashboard/message')}
              className={`flex items-center p-2 mt-5 ${
                activeItem === '/dashboard/message' ? 'bg-gray-200' : ''
              }`}
              style={{
                paddingRight: activeItem === '/dashboard/gallerys' ? '85px' : '',
                borderLeft: activeItem === '/dashboard/message' ? '4px solid #8155ff' : '',
              }}
            >
              <FaReceipt className="mr-3" /> Messages
            </Link>
          </>
        )}
        
      </nav>
    </div>
  );
};

export default Sidebar;