import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import { FaBars, FaUser, FaTimes, FaTrashAlt,} from 'react-icons/fa'; // Importing necessary icons
import logo from '../Assets/logo.jpg';
import Filter from './Filter';

const GalleryCrud = () => {
  const [photos, setPhotos] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [isOpen, setIsOpen] = useState(false); // State for mobile navigation
  const [isOpenUserMenu, setIsOpenUserMenu] = useState(false); // State for user menu
  const token = localStorage.getItem('Token');
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('https://ituzebe.onrender.com/api/photos', {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(res => {
        setPhotos(res.data);
        const uniqueCategories = [...new Set(res.data.map(photo => photo.category))];
        setCategories(uniqueCategories);
      })
      .catch(err => console.error(err));
  }, [token]);

  const handleCategoryChange = category => {
    setSelectedCategory(category);
  };

 ;

  const handleDelete = async id => {
    try {
      await axios.delete(`https://ituzebe.onrender.com/api/photos/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPhotos(photos.filter(photo => photo._id !== id));
    } catch (err) {
      console.error(err);
    }
  };


  const filteredPhotos = selectedCategory
    ? photos.filter(photo => photo.category === selectedCategory)
    : photos;

  // Function to toggle mobile navigation
  const handleNavbarToggle = () => {
    setIsOpen(!isOpen);
  };

  // Placeholder function for logout
  const handleLogout = () => {
    localStorage.removeItem('Token');
    navigate('/signin');
  };

  return (
    <div className="min-h-screen bg-stone-900 text-white flex flex-col font-Arial">
       {/* Header section */}
      <header className="sticky top-0 z-20 shadow-md flex justify-between items-center p-4 bg-gray-800">
        <div className="flex items-center space-x-4">
          <img src={logo} alt="Ituze Garden Palace Logo" className="h-20 w-20 rounded-full" />
          <h1 className="text-2xl font-bold">Ituze Garden Palace</h1>
        </div>
        <div className="flex items-center space-x-4">
          <button onClick={handleNavbarToggle} className="text-3xl md:hidden">
            {isOpen ? <FaTimes /> : <FaBars />}
          </button>
          <nav className={`flex-col md:flex-row ${isOpen ? 'flex' : 'hidden'} md:flex`}>
            <ul className="block md:flex md:space-x-4 md:items-center md:text-lg md:font-semibold md:space-x-8 md:ml-auto">
              <li className="hover:underline"><Link to="/">Home</Link></li>
              <li className="hover:underline"><Link to="/gallery">Gallery</Link></li>
              <li className="hover:underline"><Link to="/reservation">Reservation</Link></li>
              <li className="hover:underline"><Link to="/menu">Menu</Link></li>
               <li className="hover:underline"><Link to="/contact">Contacts</Link></li>
            </ul>
          </nav>
          <div className="relative">
            <FaUser className="text-3xl cursor-pointer" onClick={() => setIsOpenUserMenu(!isOpenUserMenu)} />
            {isOpenUserMenu && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 text-black">
                <Link to="/profile" className="block px-4 py-2 hover:bg-gray-200">Profile</Link>
                <button onClick={handleLogout} className="block px-4 py-2 hover:bg-gray-200">Logout</button>
              </div>
            )}
          </div>
        </div>
      </header>

      {/* Main content */}
      <div className="container mx-auto px-4 py-8 bg-white">
        <div className="mb-8 text-black">
          <h1 className="text-4xl font-semibold mb-4 text-center">Explore Our Gallery</h1>
          <Filter
            categories={categories}
            selectedCategory={selectedCategory}
            handleCategoryChange={handleCategoryChange}
          />
        </div>

        
        {/* Display Photos */}
        {filteredPhotos.length > 0 ? (
          <div>
            <h2 className="text-3xl font-semibold mb-4 text-center">{selectedCategory ? `${selectedCategory} Photos` : 'All Photos'}</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
              {filteredPhotos.map(photo => (
                <div key={photo._id} className="bg-white rounded-lg overflow-hidden shadow-lg">
                  <img src={photo.imageUrl} alt={photo.title} className="w-full h-64 object-cover" />
                  <div className="p-4">
                    <h3 className="text-lg font-semibold">{photo.title}</h3>
                    <p className="text-gray-600">{photo.description}</p>
                    <p className="text-sm text-gray-500 mt-2">{photo.category}</p>
                    <div className="flex justify-end mt-4 space-x-4">
                      <button
                        onClick={() => handleDelete(photo._id)}
                        className="text-red-500 hover:text-red-700 flex items-center focus:outline-none"
                        title="Delete Photo"
                      >
                        <FaTrashAlt className="mr-2" /> View More
                      </button>
                     
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="text-center">
            <p className="text-xl">No photos found.</p>
          </div>
        )}
      </div>

       <footer className="bg-gray-900 text-white py-10 text-center">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
            <div className="text-sm">
              <p>Kigali - Rwanda<br />Kimuhurura, KG 623 St</p>
            </div>
            <div>
              <h2 className="text-2xl font-semibold mb-2">Ituze Garden Palace</h2>
              <ul className="flex space-x-6">
                <li><a href="https://www.facebook.com/" aria-label="Facebook" className="hover:text-orange-400"><FaFacebookF /></a></li>
                <li><a href="https://twitter.com/" aria-label="Twitter" className="hover:text-orange-400"><FaTwitter /></a></li>
                <li><a href="https://www.instagram.com/" aria-label="Instagram" className="hover:text-orange-400"><FaInstagram /></a></li>
              </ul>
            </div>
            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3987.3700937917183!2d30.189746909868628!3d-2.007285197966163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19db5754ee2e87f5%3A0x8d6336f2eaad9694!2sItuze%20garden%20palace!5e0!3m2!1sen!2srw!4v1719416061512!5m2!1sen!2srw"
                width="300"
                height="200"
                style={{ border: '0' }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="rounded-lg shadow-lg"
               title="Ituze Garden Palace Location"
              ></iframe>
            </div>
          </div>
          <div className="mt-6 text-sm">
            <p>FAQ | Terms & Conditions | Privacy Policy | Shipping Policy | Refund Policy | Cookie Policy</p>
          </div>
          <div className="mt-4 text-xs">
            <p>&copy;2024 Ituze Garden Palace. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default GalleryCrud;
