import React, { useState } from 'react';
import { Outlet,} from 'react-router-dom';
import Sidebar from './Sidebar';
import Navbar from './Navbar';


const DashboardLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
 

  const toggleSidebar = () => {
    setIsSidebarOpen(isSidebarOpen);
  };

  

  return (
    <div className="">
      {isSidebarOpen && <Sidebar onClose={toggleSidebar} />}

      <div className={`flex-grow ${isSidebarOpen ? 'ml-56' : 'ml-0'}`}>
        <div className='font-roboto'>
          <Navbar/>
          <Outlet/>
          </div>
      
      </div>
      
  
    </div>
  );
};

export default DashboardLayout;
