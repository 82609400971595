import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaFacebookF, FaTwitter, FaInstagram, FaUser, FaBars } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import backgroundImage from '../Assets/restaurant.jpg';
import chickenImage from '../Assets/chicken.jpg';
import logo from '../Assets/logo.jpg';
import { FaTimes } from 'react-icons/fa'; // Importing necessary icons

const LandingPage = () => {
  const [reservations, setReservations] = useState([]);
const [isOpenUserMenu, setIsOpenUserMenu] = useState(false); // State for user menu
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchReservations();
  }, []);

  const fetchReservations = async () => {
    try {
      const response = await axios.get('https://ituzebe.onrender.com/api/reservations');
      setReservations(response.data);
    } catch (error) {
      console.error('Error fetching reservations:', error);
    }
  };


  const handleNavbarToggle = () => {
    setIsOpen(!isOpen);
  };

  
  const handleLogout = () => {
    localStorage.removeItem('Token');
    navigate('/signin');
  };



  return (
    <div className="min-h-screen bg-gray-900 text-white flex flex-col font-Arial">
       {/* Header section */}
      <header className="sticky top-0 z-20 shadow-md flex justify-between items-center p-4 bg-gray-800">
        <div className="flex items-center space-x-4">
          <img src={logo} alt="Ituze Garden Palace Logo" className="h-20 w-20 rounded-full" />
          <h1 className="text-2xl font-bold">Ituze Garden Palace</h1>
        </div>
        <div className="flex items-center space-x-4">
          <button onClick={handleNavbarToggle} className="text-3xl md:hidden">
            {isOpen ? <FaTimes /> : <FaBars />}
          </button>
          <nav className={`flex-col md:flex-row ${isOpen ? 'flex' : 'hidden'} md:flex`}>
            <ul className="block md:flex md:space-x-4 md:items-center md:text-lg md:font-semibold md:space-x-8 md:ml-auto">
              <li className="hover:underline"><Link to="/">Home</Link></li>
              <li className="hover:underline"><Link to="/gallery">Gallery</Link></li>
              <li className="hover:underline"><Link to="/reservation">Reservation</Link></li>
              <li className="hover:underline"><Link to="/menu">Menu</Link></li>
               <li className="hover:underline"><Link to="/contact">Contacts</Link></li>
            </ul>
          </nav>
          <div className="relative">
            <FaUser className="text-3xl cursor-pointer" onClick={() => setIsOpenUserMenu(!isOpenUserMenu)} />
            {isOpenUserMenu && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 text-black">
                <Link to="/profile" className="block px-4 py-2 hover:bg-gray-200">Profile</Link>
                <button onClick={handleLogout} className="block px-4 py-2 hover:bg-gray-200">Log</button>
              </div>
            )}
          </div>
        </div>
      </header>

      <main className="flex-1 flex items-center justify-center text-center py-20 relative"
        style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <div className="p-8 bg-black bg-opacity-60 rounded-lg space-y-6 max-w-lg mx-auto">
          <h2 className="text-2xl md:text-4xl font-bold text-orange-400">Restaurant Experience</h2>
          <p className="text-lg md:text-2xl">
            Experience Ituze Garden Palace: Fine dining, rooftop views, and the best craft cocktails in Kigali.
          </p>
          <button className="mt-8 mb-32">
            <Link to="/menu" className="bg-transparent border border-white px-6 py-3 rounded-lg hover:bg-white hover:text-black transition">
              View Menu
            </Link>
          </button>
        </div>
      </main>

      <section className="py-16 bg-orange-100 text-center">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-4">
          {reservations.map((reservation) => (
            <div key={reservation._id} className="bg-gray-800 rounded-lg shadow-lg p-6 text-left">
              <h3 className="text-xl font-bold mb-2 text-orange-400">{reservation.name}</h3>
              <p className="mb-2 text-gray-300">{reservation.description}</p>
              <p className="text-lg font-semibold text-white">RF {reservation.price}</p>
              <button
                className="mt-4 bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded transition"
              >
                Confirm with Deposit
              </button>
            </div>
          ))}
        </div>
      </section>

      <section className="relative py-16 text-center bg-fixed bg-cover bg-center text-white"
        style={{ backgroundImage: `url(${chickenImage})` }}>
        <div className="bg-black bg-opacity-70 py-16 px-8 rounded-lg max-w-lg mx-auto">
          <h2 className="text-4xl md:text-5xl font-bold text-orange-400">Satisfy Your Craving</h2>
          <p className="text-2xl md:text-3xl font-semibold mt-4">Order Online</p>
          <Link to="/order" className="mt-6 inline-block bg-transparent border border-white px-8 py-3 rounded-lg hover:bg-white hover:text-black transition">
            Order Now
          </Link>
        </div>
      </section>

      <section className="bg-gray-100 py-16 text-center">
        <Carousel
          showThumbs={false}
          showStatus={false}
          infiniteLoop
          autoPlay
          interval={5000}
          transitionTime={500}
          className="w-full max-w-4xl mx-auto"
        >
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h3 className="text-2xl font-semibold mb-5 text-gray-800">Jackson Duvert</h3>
            <p className="text-gray-700">This place is very nice and has great food. The view is amazing at night!! A must-go.</p>
          </div>
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h3 className="text-2xl font-semibold mb-5 text-gray-800">Another Testimonial</h3>
            <p className="text-gray-700">Insert another testimonial here.</p>
          </div>
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h3 className="text-2xl font-semibold mb-5 text-gray-800">Yet Another Testimonial</h3>
            <p className="text-gray-700">Insert another testimonial here.</p>
          </div>
        </Carousel>
      </section>

      <section className="bg-gray-800 text-white py-12 text-center">
        <div className="container mx-auto flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4 px-4">
          <input
            type="email"
            placeholder="Enter your email here*"
            className="bg-gray-700 border border-gray-600 px-4 py-2 rounded focus:outline-none"
          />
          <button className="bg-orange-500 px-6 py-3 rounded text-lg font-semibold hover:bg-orange-600 transition">
            Subscribe Now
          </button>
        </div>
      </section>

      <footer className="bg-gray-900 text-white py-10 text-center">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
            <div className="text-sm">
              <p>Kigali - Rwanda<br />Kimuhurura, KG 623 St</p>
            </div>
            <div>
              <h2 className="text-2xl font-semibold mb-2">Ituze Garden Palace</h2>
              <ul className="flex space-x-6">
                <li><a href="https://www.facebook.com/" aria-label="Facebook" className="hover:text-orange-400"><FaFacebookF /></a></li>
                <li><a href="https://twitter.com/" aria-label="Twitter" className="hover:text-orange-400"><FaTwitter /></a></li>
                <li><a href="https://www.instagram.com/" aria-label="Instagram" className="hover:text-orange-400"><FaInstagram /></a></li>
              </ul>
            </div>
            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3987.3700937917183!2d30.189746909868628!3d-2.007285197966163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19db5754ee2e87f5%3A0x8d6336f2eaad9694!2sItuze%20garden%20palace!5e0!3m2!1sen!2srw!4v1719416061512!5m2!1sen!2srw"
                width="300"
                height="200"
                style={{ border: '0' }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="rounded-lg shadow-lg"
               title="Ituze Garden Palace Location"
              ></iframe>
            </div>
          </div>
          <div className="mt-6 text-sm">
            <p>FAQ | Terms & Conditions | Privacy Policy | Shipping Policy | Refund Policy | Cookie Policy</p>
          </div>
          <div className="mt-4 text-xs">
            <p>&copy;2024 Ituze Garden Palace. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
